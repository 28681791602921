<template>
  <div class="footersection">
    <div class="container is-flex is-justify-content-space-between is-align-items-center _innerfooter">
      <div class="_social">

        <span v-if="$utils.getAttribVal('', 'jb_facebook_link')">
          <a :href="$utils.getAttribVal('', 'jb_facebook_link')" target="_blank">
            <img src="../../assets/facebook-icon.svg" alt="Facebook" />
          </a>
        </span>

        <span v-if="$utils.getAttribVal('', 'jb_insta_link')">
          <a :href="$utils.getAttribVal('', 'jb_insta_link')" target="_blank">
            <img src="../../assets/instagram-icon.svg" alt="Instagram" />
          </a>
        </span>

        <span v-if="$utils.getAttribVal('', 'jb_twitter_link')">
          <a :href="$utils.getAttribVal('', 'jb_twitter_link')" target="_blank">
            <img src="../../assets/twitter-icon.svg" alt="Twitter" />
          </a>
        </span>

        <span v-if="$utils.getAttribVal('', 'jb_twitter_link')">
          <a :href="$utils.getAttribVal('', 'jb_twitter_link')" target="_blank">
            <img src="../../assets/youtube-icon.svg" alt="Youtube" />
          </a>
        </span>

        <span v-if="$utils.getAttribVal('', 'jb_twitter_link')">
          <a :href="$utils.getAttribVal('', 'jb_twitter_link')" target="_blank">
            <img src="../../assets/discord-icon.svg" alt="Discord" />
          </a>
        </span>

        <span v-if="$utils.getAttribVal('', 'jb_twitter_link')">
          <a :href="$utils.getAttribVal('', 'jb_twitter_link')" target="_blank">
            <img src="../../assets/google_news-icon.svg" alt="Goolge News" />
          </a>
        </span>



        <span v-if="$utils.getAttribVal('', 'jb_discord_link')">
          <a :href="$utils.getAttribVal('', 'jb_discord_link')" target="_blank">
            <img src="../../assets/discord-icon.svg" alt="Discord" />
          </a>
        </span>


        <span v-if="$utils.getAttribVal('', 'jb_youtube_link')">
          <a :href="$utils.getAttribVal('', 'jb_youtube_link')" target="_blank">
            <img src="../../assets/youtube-icon.svg" alt="Youtube" />
          </a>
        </span>



        <span v-if="$utils.getAttribVal('', 'jb_google_news_link')">
          <a :href="$utils.getAttribVal('', 'jb_google_news_link')" target="_blank">
            <img src="../../assets/google_news-icon.svg" alt="Google News" />
          </a>
        </span>


        <!-- <a href="https://www.instagram.com" target="_blank">
          <img src="../../assets/instagram-icon.svg" alt="Instagram" />
        </a>
        <a href="https://twitter.com" target="_blank">
          <img src="../../assets/twitter-icon.svg" alt="Twitter" />
        </a>
        <a href="https://www.facebook.com" target="_blank">
          <img src="../../assets/facebook-icon.svg" alt="Facebook" />
        </a> -->
      </div>

      <div class="_shortlinks">
        <router-link to="/">Home</router-link>
        <span v-for="objRootPage, idx of objJackpotBranding.branding_page_roots" :key="idx">
          <a href="javascript: void(0);" @click="getPageLink(objRootPage)">

            {{ objRootPage.name }}
          </a>
        </span>
        <!--  -->
        <!-- <router-link to="/terms-conditions">Terms &amp; Conditions</router-link>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
        <router-link to="/responsible-play">Responsible Play</router-link>
        <router-link to="/faq">FAQ</router-link> -->
      </div>

      <!-- <div class="_countryflag">
        <img src="../../assets/ukraine-flag.svg" alt="Ukraine Flag" />
      </div> -->
    </div>
  </div>
</template>



<script>
import { mapGetters } from 'vuex';
import Redirector from '@/views/Redirector.vue';

export default {
  components: {
    Redirector,
  },
  computed: {
    ...mapGetters(['objUser', 'blnIsUserLoggedIn', 'intJackpotBrandingId', 'objJackpotBranding']),
  },
  props: ['intId'],
  data() {
    return {
      objPage: null
    }
  },
  async created() {
  },
  methods: {
    getPageLink(obj) {
      // return `/pages/${obj.id}`;
      this.$router.replace({
        name: 'Redirector',
        params: { strTarget: `/pages/${parseInt(obj.id)}` },
      });
    }
  },
};
</script>
  
<style></style>
  

 