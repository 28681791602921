import dayjs from 'dayjs';
import NoImage from '../assets/no_team.jpg';
import CoinImg from '@/assets/coins.svg';
import TokenImg from '@/assets/token.svg';
import KOFC_BONUS_JACKPOT_DEFAULT_IMG from '@/assets/kofc_bonus.png';
import PS6_BONUS_JACKPOT_DEFAULT_IMG from '@/assets/ps6-bonus.png';
import LOWER_BANNER from '@/assets/lower-banner.png';

const utils = {
  //ONLY CHANGE THIS FOR SWITCHING DEPLOYMENTS
  // intJackpotBrandingId: 6, //	5-KofC, 6-playspuer6, 1 - Demo Client, esports, 7-888

  scrollToTop() {
    // window.scrollTo(0, 0);
    // window.parent.scrollTo(0, 0);
  },

  // getDomain() {
  //   switch (this.intJackpotBrandingId) {
  //     case 5: //KOFc
  //       return 'kingsofcricket.com';
  //       break;
  //     case 6: //play super 6
  //       return 'playsuper6.com';
  //       break;
  //     case 1: //1 - Demo Client, esports
  //       return 'staging.espkings.com';
  //       break;
  //   }
  // },

  // getCssLink(strJackpotBrandingId) {
  //   switch (parseInt(strJackpotBrandingId)) {
  //     case 5: //KOFc
  //       return './css/KOfC.min.css';
  //       break;
  //     case 6: //play super 6
  //       return './css/playsuper6.min.css';
  //       break;
  //     case 1: //1 - Demo Client, esports
  //       return './css/espkings.min.css';
  //       break;
  //   }
  // },

  // getExternalCssLink(strJackpotBrandingId) {
  //   switch (parseInt(strJackpotBrandingId)) {
  //     case 6: //play super 6
  //       return 'https://prod.gj2.imprexisplatform.com/playsuper6.min.css';
  //       break;
  //     case 7: //888free
  //       return 'https://prod.gj2.imprexisplatform.com/playsuper6.min.css';
  //       break;
  //     case 1: //1 - Demo Client, esports
  //       return 'https://prod.gj2.imprexisplatform.com/espkings.min.css';
  //       break;
  //     case 5: //KOFc
  //       return 'https://prod.gj2.imprexisplatform.com/KOfC.min.css';
  //       break;
  //   }
  //   return '';
  // },

  //remains static do not machange
  arrJackpotBrandingDetailsHidden: [13],
  intCricketSportId: 6, //6 - sport id of cricket in imprexis database
  arrWicketsRange: [
    {
      id: 1,
      value: '1-2',
    },
    {
      id: 2,
      value: '2-3',
    },
    {
      id: 3,
      value: '3-4',
    },
    {
      id: 4,
      value: '4-5',
    },
    {
      id: 5,
      value: '5-6',
    },
    {
      id: 6,
      value: '6-7',
    },
    {
      id: 7,
      value: '7-8',
    },
    {
      id: 8,
      value: '8-9',
    },
    {
      id: 9,
      value: '9-10',
    },
  ],
  arrRunsRange: [
    {
      id: 1,
      value: '1-10',
    },
    {
      id: 2,
      value: '11-20',
    },
    {
      id: 3,
      value: '21-30',
    },
    {
      id: 4,
      value: '31-40',
    },
    {
      id: 5,
      value: '41-50',
    },
    {
      id: 6,
      value: '51-60',
    },
    {
      id: 7,
      value: '61-70',
    },
    {
      id: 8,
      value: '71-80',
    },
    {
      id: 9,
      value: '81-90',
    },
    {
      id: 10,
      value: '91-100',
    },
    {
      id: 11,
      value: '101-110',
    },
  ],
  // isDetailHidden(intJackpotBrandingId) {
  //   if (this.arrJackpotBrandingDetailsHidden.includes(intJackpotBrandingId)) return true;
  //   return false;
  // },
  //cloud front url
  getUploadsBaseUrl() {
    return 'https://d2a5lun95gez2a.cloudfront.net';
  },
  getTeamNoImage() {
    return NoImage;
  },
  getKofCBonusDefaultImg() {
    return KOFC_BONUS_JACKPOT_DEFAULT_IMG;
  },
  getPs6BonusDefaultImg() {
    return PS6_BONUS_JACKPOT_DEFAULT_IMG;
  },
  getLowerBanner() {
    return LOWER_BANNER;
  },
  formatDate(strDate) {
    return dayjs(strDate).format('D MMM YYYY');
  },
  formatDateTimeDisplayFixture(strDateTime) {
    return dayjs(strDateTime).format('D MMM YYYY HH:mm');
  },
  formatDateTimeDisplay(strDateTime) {
    return dayjs(strDateTime).format('D MMM YYYY HH:mm');
  },
  formatDateTime(strDateTime) {
    return dayjs(strDateTime).format('YYYY-MM-DD HH:mm');
  },
  formatDateTime2(strDateTime) {
    return dayjs(strDateTime).format('HH:mm DD/MM/YYYY');
  },
  postHeight() {
    // if (process.env.NODE_ENV == 'production') {
    //   window.parent.postMessage({ e_name: 'resizeWindow', windowHeight: document.body.scrollHeight });
    // } else {
    //   window.parent.postMessage({ e_name: 'resizeWindow', windowHeight: document.body.scrollHeight }, 'http://localhost');
    // }
  },
  emitWCEvent(ref, strEvent, objData) {
    //ref is this
    ref.$root.$children[0].$emit(strEvent, { data: objData });
  },
  emitWCEvent2($root, strEvent, objData) {
    //ref is this
    $root.$children[0].$emit(strEvent, objData);
  },

  async loadResource($myAxios, strUrl) {
    try {
      const objData = await $myAxios.get(strUrl, {});

      if (objData.blnOK) {
        if (objData.arr) {
          return objData.arr;
        }
        if (objData.obj.rows) {
          return objData.obj.rows;
        } else if (objData.obj) {
          return objData.obj;
        }
      }
    } catch (e) {
      console.log(e);
    }
    return [];
  },

  getDate(strDate, strFormat) {
    try {
      if (strFormat == 'LOCAL') {
        return new Date(strDate).toLocaleString();
      } else {
        return strDate;
        //return date.formatDate(new Date(objFixture.d), 'YYYY-MM-DDTHH:mm:ss.SSSZ')
      }
      //  return objFixture.d.toLocaleString();
    } catch (e) {
      console.log(e);
      return 'n/a';
    }
  },

  isQuestionTypeFixtures(objQuestion) {
    return objQuestion.question_type == 'fixtures' ? true : false;
  },

  isCricketJackpotGame(objJackpotGame) {
    if (objJackpotGame.sport_id === this.intCricketSportId) {
      return true;
    }
    return false;
  },

  showSuccessNotice() {},

  ucwords(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  },

  capitalize(str) {
    const arr = str.split(' ');

    //loop through each element of the array and capitalize the first letter.
    for (var i = 0; i < arr.length; i++) {
      let word = arr[i];
      //do not ca[italize these
      if (!['a', 'the', 'in', 'have', 'will', 'with', 'of'].includes(word)) {
        word = word.charAt(0).toUpperCase() + word.slice(1);
      }
      arr[i] = word;
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const str2 = arr.join(' ');
    return str2;
  },
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  getUploadedFileUrl(strImg) {
    //s3 url
    if (strImg.includes('imprexis-banners-bucket')) {
      return strImg;
    }
    //cloud front url appended with image name
    let strUploadsUrl = 'https://d2a5lun95gez2a.cloudfront.net';
    return `${strUploadsUrl}/${strImg}`;
  },
  getCurrency(strCurrency, strAmount) {
    if (strAmount == '0') return '';
    switch (strCurrency) {
      case 'Coin':
        return `<img style="margin-right: 2px;" width="13px" src="${CoinImg}" />`;
      case 'Token':
        return `<img style="margin-right: 2px;" width="13px" src="${TokenImg}" />`;
      case 'GBP':
        return `€`;
      case 'USD':
        return `$`;
    }
    return strCurrency;
  },
  sleep(secs) {
    //default for playing questions
    if (!secs) {
      secs = 1;
    }

    return new Promise((r) => setTimeout(r, secs * 1000));
  },

  isJackpotGameP2P(objJackpotGame) {
    let arrJGBetOptions = JSON.parse(objJackpotGame.bet_options_json);
    if (arrJGBetOptions.includes('0')) {
      return false;
    }
    return true;
  },

  isJackpotGameF2P(objJackpotGame) {
    let arrJGBetOptions = JSON.parse(objJackpotGame.bet_options_json);
    if (arrJGBetOptions.includes('0')) {
      return true;
    }
    return false;
  },
  isVirtualCurrency(objJackpotGame) {
    switch (objJackpotGame.currency) {
      case 'Coin':
        return true;
      case 'Token':
        return true;
      case 'GBP':
        return false;
      case 'USD':
        return false;
    }
    return false;
  },
  getQuestionText(objQuestion) {
    // if (this.$utils.isQuestionTypeFixtures(objQuestion)) {
    // if (this.$utils.isCricketJackpotGame(this.objJackpotGame)) {
    // if (objQuestion.objFixture) {
    //   let strHomeTeam = objQuestion.objFixture && objQuestion.objFixture.home_team ? objQuestion.objFixture.home_team.name : '';
    //   let strAwayTeam = objQuestion.objFixture && objQuestion.objFixture.away_team ? objQuestion.objFixture.away_team.name : '';
    //   let strQuestion = objQuestion.question;
    //   return `${strHomeTeam} V ${strAwayTeam} - ${strQuestion}`;
    // }

    if (objQuestion.fixture) {
      let strHomeTeam = objQuestion.fixture && objQuestion.fixture.home_team ? objQuestion.fixture.home_team.name : '';
      let strAwayTeam = objQuestion.fixture && objQuestion.fixture.away_team ? objQuestion.fixture.away_team.name : '';
      let strQuestion = objQuestion.question;
      return `${strHomeTeam} v ${strAwayTeam} - ${strQuestion}`;
    }
    // }
    // }

    return objQuestion.question;
  },

  async bindFixturesToQuestion(objJackpotGame, $myAxios) {
    try {
      //array of promises
      let arrPromises = [];

      //iterate questions and push promise of fixtures
      for (let objQuestion of objJackpotGame.jackpot_game_questions) {
        if (this.isQuestionTypeFixtures(objQuestion)) {
          //fixture exist, push to promise
          if (objQuestion.fixture_id) {
            let pFixture = $myAxios.get(`/fixtures/${objQuestion.fixture_id}`);
            arrPromises.push(pFixture);
          }
        }
      }

      //respose of promises
      if (arrPromises.length) {
        let arrResponses = await Promise.all(arrPromises);
        for (let objRes of arrResponses) {
          if (objRes.blnOK) {
            //find fixture in response
            let objFixture = objRes.obj;

            //find the game ques and append fixture to it
            let objGameQues = objJackpotGame.jackpot_game_questions.find((o) => o.fixture_id == objFixture.id);
            objGameQues.objFixture = objFixture;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  },
};

export default utils;
