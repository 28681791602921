import axios from 'axios';

let strBaseUrl = 'http://localhost:3000/api/';

if (process.env.NODE_ENV == 'production') {
  strBaseUrl = 'https://api.imprexisplatform.com/api/';
} else {
  strBaseUrl = 'http://localhost:3000/api/';
}
// strBaseUrl = 'https://api.imprexisplatform.com/api/';
 
let strBearerToken = null; 

//import { accessToken } from "../composables/auth";
const axiosPlugin = {
  install(Vue, options) {
    let { i18n, store, router } = options;

    Vue.prototype.$axios = axios;

    const axiosInstance = axios.create({
      baseURL: strBaseUrl,
    });

    Vue.prototype.$myAxios = axiosInstance;
    Vue.prototype.setAxiosToken = (strToken) => {
      strBearerToken = strToken;
    };

    //axiosInstance.defaults.baseURL = process.env.VUE_APP_BASE;
    axiosInstance.defaults.headers['Content-Type'] = 'application/json';
    axiosInstance.interceptors.request.use((req) => {
      if (strBearerToken) {
        req.headers['Authorization'] = `Bearer ${strBearerToken}`;
      }

      try {
        let strLocale = i18n.locale;
        if (!req.params) {
          req.params = {};
        }
        req.params.locale = strLocale;
      } catch (e) {
        console.log(e);
      }

      return req;
    });

    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response.data;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
    axiosInstance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        return response.data;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log('I M HERE', error.response);
        // if (error.response && error.response.status == 401) {
        //   router.replace({ name: 'SignIn' });
        // }
        return Promise.reject(error);
      }
    );
  },
};

export default axiosPlugin;
