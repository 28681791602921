import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import createPersistedState from 'vuex-persistedstate';

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    objSignInData: { objUser: {}, strToken: '' },
    objWalletStats: {},
    isJBModalClosed: false,
    intJackpotBrandingId: null,
    objJackpotBranding: {},
  },
  getters: {
    objJackpotBranding(state) {
      return state.objJackpotBranding;
    },
    intJackpotBrandingId(state) {
      return state.intJackpotBrandingId;
    },
    isJBModalClosed(state) {
      return state.isJBModalClosed;
    },
    strToken: (state) => {
      try {
        return state.objSignInData.strToken;
      } catch (e) {
        console.log(e);
      }
      return '';
    },
    objUser: (state) => {
      try {
        return state.objSignInData.objUser;
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    blnIsUserLoggedIn: (state) => {
      try {
        if (state.objSignInData.objUser.player_id) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    },

    objWalletStats: (state) => {
      try {
        return state.objWalletStats;
      } catch (e) {
        console.log(e);
      }
      return '';
    },
  },

  mutations: {
    closeJBModal(state) {
      state.isJBModalClosed = true;
    },
    resetState(state) {
      state.arrCartItems = [];

      state.arrLines = [
        {
          arrNumbers: [],
          blnDisabled: false,
        },
      ];
      state.objSignInData = { objUser: {}, strToken: '' };
    },
    signIn(state, objSignInData) {
      try {
        // console.log(objSignInData);

        let { objUser, strToken } = objSignInData;

        state.objSignInData.objUser = objUser;
        state.objSignInData.strToken = strToken;
      } catch (e) {
        console.log(e);
      }
    },

    logout(state) {
      try {
        state.objSignInData = { objUser: {}, strToken: '' };
        state.arrCartItems = [];
        state.arrLines = [];
      } catch (e) {
        console.log(e);
      }
    },
    setWalletStats(state, objWalletStats) {
      try {
        state.objWalletStats = objWalletStats;
      } catch (e) {
        console.log(e);
      }
    },

    setJackpotBranding(state, objJackpotBranding) {
      try {
        state.objJackpotBranding = objJackpotBranding;
      } catch (e) {
        console.log(e);
      }
    },
    setJackpotBrandingId(state, intJackpotBrandingId) {
      try {
        state.intJackpotBrandingId = intJackpotBrandingId;
        console.log('Setting branding', intJackpotBrandingId);
      } catch (e) {
        console.log(e);
      }
    },
  },
  actions: {},
  modules: {},
});
