<!-- <style src="../node_modules/buefy/dist/buefy.min.css"></style> -->
<style>
/* @import url('https://unpkg.com/buefy@0.9.23/dist/buefy.min.css'); */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&family=Roboto:wght@400;500&display=swap');
/* @import url('https://demowebprojects.com/2022/esp/KOfC.min.css'); */
/* @import url('https://demowebprojects.com/2022/esp/playsuper6.min.css'); */
</style>
<style src="./css/buefy.min.css"></style>
<style src="./css/customstyle.min.css"></style>
<style src="./css/customstyle2.css"></style>
<!-- <style src="./css/KOfC.min.css"></style> -->
<!-- <style src="./css/playsuper6.min.css"></style> -->

<!-- <link href="https://prod.gj2.imprexisplatform.com/KOfC.min.css" />
<link href="https://prod.gj2.imprexisplatform.com/playsuper6.min.css" />  -->

<style src="./css/responsive.min.css"></style>

<style src="../node_modules/vue-slick-carousel/dist/vue-slick-carousel.css"></style>
<style src="../node_modules/vue-slick-carousel/dist/vue-slick-carousel-theme.css"></style>

<template>
  <div id="app">
    <component :is="'style'">
      {{ objJackpotBranding.css }}
    </component>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import store from './store';
import { router } from './router';
import axiosPlugin from './plugins/axios';
import utilsPlugin from './plugins/utils';
import { i18n } from './i18n';

//import Buefy from "buefy";
import { Table, Input, Numberinput, Select, Modal, Button, Autocomplete, Field, Tooltip, Radio, Snackbar, Upload, Slider, Carousel } from 'buefy';
import Tabs from 'vue-tabs-component';

//Vue.use(Buefy);
// Vue.use(axiosPlugin);

Vue.use(Table);
Vue.use(Input);
Vue.use(Numberinput);
Vue.use(Select);
Vue.use(Button);
Vue.use(Autocomplete);
Vue.use(Field);
Vue.use(Tooltip);
Vue.use(Radio);
Vue.use(Snackbar);
Vue.use(Tabs);
Vue.use(Upload);
Vue.use(Modal);
Vue.use(Slider);
Vue.use(Carousel);
Vue.use(axiosPlugin, { router, store, i18n });
Vue.use(utilsPlugin, { router, store });

import { mapGetters } from 'vuex';

export default {
  name: 'app',
  emits: ['stats'],
  data() {
    return {
      // windowHeight: 900,
    };
  },
  // props: ['client', 'branding_id'],
  props:
  {
    client: {
      type: String,
      default: 'hashmi',
    },
    branding_id: {
      type: String,
      default: '5',
    },
  },
  // },
  // },
  i18n,
  router,
  store,
  computed: {
    ...mapGetters(['blnIsUserLoggedIn', 'strToken', 'objJackpotBranding']),
  },
  async created() {

    //set branding id in store for use in app
    this.$store.commit("setJackpotBrandingId", this.branding_id);

    //find the jackpot branding from api and commit it in the store for use inside the widget everywhere
    let objResponse = await this.$myAxios.get(`/jackpot-brandings/${this.branding_id}?game_status=act`);
    if (objResponse.blnOK) {
      let objJackpotBranding = objResponse.obj;
      this.$store.commit("setJackpotBranding", objJackpotBranding);
    }

    //change language event for bubbling
    window.addEventListener('changeLang', (event) => {
      console.log(event);
      let strLang = event.detail;
      this.$i18n.locale = strLang;
    });
    // window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    // window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
    //alert(this.client + "can axios");
    // router.push("/home");


    try {


      //for production injection
      // let head = document.getElementsByTagName('gj-widget')[0];
      // var link = document.createElement('link');
      // link.id = 'you';
      // link.rel = 'stylesheet';
      // link.type = 'text/css';
      // link.href = this.$utils.getExternalCssLink(this.branding_id);
      // link.media = 'all';
      // head.shadowRoot.appendChild(link);

      // for local injection
      // var link = document.createElement('link');
      // link.setAttribute('rel', 'stylesheet');
      // link.setAttribute('type', 'text/css');
      // link.setAttribute('href', this.$utils.getExternalCssLink(this.branding_id));
      // document.getElementsByTagName('head')[0].appendChild(link);

    } catch (e) {
      // console.log(e);
    }

    if (this.blnIsUserLoggedIn) {
      this.setAxiosToken(this.strToken);
    }
  },
  methods: {
    // handleResize() {
    //   if (document.body.scrollHeight > 600) {
    //     this.windowHeight = document.body.scrollHeight;
    //     this.$utils.postMessage(this.windowHeight);
    //   }
    // },
  },
  // watch: {
  //   // whenever windowHeight changes, this function will run
  //   windowHeight(newWindowHeight) {
  //     console.log('new window height', newWindowHeight);
  //     this.$utils.postMessage(newWindowHeight);
  //   },
  // },
};
</script>
