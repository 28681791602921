<template>
    <div>
        <div class="mainbody" :class="objUser && objUser.id ? 'logged_in' : 'notloggedin'">
            <div class="header">
                <!-- <div class="sidebarmenu" v-if="open == true">
                    <div class="_menulist">
                        <router-link to=""><span><img src="@/assets/csgo-icon.svg" alt="" /></span>
                            CS:GO</router-link>
                        <router-link to=""><span><img src="@/assets/leaguelegends-icons.svg" alt="" /></span>League of
                            Legends</router-link>
                        <router-link to=""><span><img src="@/assets/dota-icon.svg" alt="" /></span>Dota
                            2</router-link>
                        <router-link to=""><span><img src="@/assets/valorant-icon.svg"
                                    alt="" /></span>Valorant</router-link>
                        <router-link to=""><span><img src="@/assets/rainbowsix-icon.svg" alt="" /></span>Rainbow
                            Six</router-link>
                        <router-link to=""><span><img src="@/assets/overwatch-icon.svg"
                                    alt="" /></span>Overwatch</router-link>
                        <router-link to=""><span><img src="@/assets/starcarft2-icon.svg" alt="" /></span>StarCraft
                            II</router-link>
                        <router-link to=""><span><img src="@/assets/rocketleague-icon.svg" alt="" /></span>Rocket
                            League</router-link>
                    </div>
                </div> -->

                <div class="header_inner">
                    <div class="container is-flex is-justify-content-space-between is-align-content-center">
                        <!-- <b-button class="menuBar is-ghost" @click="open = !open">
                <img src="@/assets/menu-bar.svg" alt="" />
              </b-button> -->
                        <div class="leftHead">

                            <div class="desktop" v-if="$utils.getAttribVal('', 'jb_header_logo_desktop')">
                                <router-link to="/">
                                    <div v-html="$utils.getAttribVal('', 'jb_header_logo_desktop')">
                                    </div>
                                </router-link>
                            </div>

                            <div class="mobile" v-if="$utils.getAttribVal('', 'jb_header_logo_mobile')">
                                <router-link to="/">
                                    <div v-html="$utils.getAttribVal('', 'jb_header_logo_mobile')">
                                    </div>
                                </router-link>
                            </div>

                            <!-- <div class="logo">
                                <router-link to="/">
                                    <span>

                                    </span>
                                </router-link>
                            </div> -->

                            <!-- <div class="menuSection">
                <router-link to="/betting">betting</router-link>
                <router-link to="/">grand jackpot</router-link>
                <router-link style="height:30px" to="/"
                  ><img src="@/assets/game-challenger.svg" alt=""
                /></router-link>
              </div>  -->
                        </div>

                        <div class="rightHead head_inner" v-if="blnIsUserLoggedIn">
                            <div class="username">
                                <span>
                                    <img src="@/assets/usericon.svg" alt="" />
                                    <router-link to="/profile">
                                        <template v-if="objUser.first_name">
                                            {{ objUser.first_name }}
                                        </template>
                                        <template v-else-if="objUser.mobile">
                                            {{ objUser.mobile }}
                                        </template>
                                    </router-link>
                                </span>
                            </div>
                            <a class="button primarybtn is-small" href="javascript:;" @click="logout">Logout</a>
                        </div>
                        <div class="head_inner" v-else>
                            <router-link class="button primarybtn _white is-small" to="/sign-in">Sign In</router-link>
                            <router-link class="button primarybtn is-small" to="/sign-up">Sign Up</router-link>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container">
                <div class="contentdiv">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import Footer from './Footer.vue'

export default {
    data() {
        return {
            open: false,
            overlay: true,
            fullheight: true
        }
    },
    components: { Footer },
    computed: {
        ...mapGetters(['objUser', 'blnIsUserLoggedIn', 'strToken', 'intJackpotBrandingId'])
    },

    methods: {
        logout() {
            this.$store.commit('logout')
            this.$router.replace('sign-in')
        }
    }
}
</script>
  