<template>
  <div>
    <GrandJackpot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GrandJackpot from './components/GrandJackpot.vue'
// import FixtureList from './components/FixtureList.vue'

export default {
  data() {
    return {
    }
  },
  components: { GrandJackpot },

  async created() {
  },
  computed: {
  },

  methods: {
  }
}
</script>
