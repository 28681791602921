<template>
  <div>Redirecting...</div>
</template>

<script>
export default {
  props: {
    strTarget: {
      type: String,
      required: true,
    },
  },
  created() {
    this.$router.replace({ path: this.strTarget });
  },
};
</script>

<style></style>
