import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
//import MainLayout from "../layouts/MainLayout.vue";
import MainLayout from '../layouts/MainLayout.vue';

const router = new VueRouter({
  mode: 'hash',

  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // console.log('scrolling to top');
    return { top: 0 };
  },
  routes: [
    {
      path: '/',
      name: '',
      component: MainLayout,
      children: [
        {
          path: '',
          name: 'Home',
          props: true,

          component: () => import('../views/Home.vue'),
        },
        {
          path: 'sign-in',
          name: 'SignIn',
          props: true,

          component: () => import('../views/SignIn.vue'),
        },

        {
          path: 'sign-up',
          name: 'SignUp',
          props: true,
          component: () => import('../views/SignUp.vue'),
        },
        {
          path: 'profile',
          name: 'Profile',
          props: true,
          component: () => import('../views/Profile.vue'),
        },

        {
          path: 'forgot-password',
          name: 'ForgotPassword',
          props: true,
          component: () => import('../views/ForgotPassword.vue'),
        },
        {
          path: 'grand-jackpots/:intGJackpotId',
          name: 'GrandJackpot',
          props: true,
          component: () => import('../views/GrandJackpot.vue'),
        },
        {
          path: 'pages/:intId',
          name: 'BrandingPage',
          props: true,
          component: () => import('../views/BrandingPage.vue'),
        },
        {
          path: 'bet-placed',
          name: 'BetPlaced',
          props: true,
          component: () => import('../views/BetPlaced.vue'),
        },
        {
          path: 'betting-history',
          name: 'BettingHistory',
          props: true,
          component: () => import('../views/BettingHistory.vue'),
        },
        {
          path: 'terms-conditions',
          name: 'TermsConditions',
          props: true,
          component: () => import('../views/TermsConditions.vue'),
        },
        {
          path: 'privacy-policy',
          name: 'PrivacyPolicy',
          props: true,
          component: () => import('../views/PrivacyPolicy.vue'),
        },
        {
          path: 'responsible-play',
          name: 'ResponsiblePlay',
          props: true,
          component: () => import('../views/ResponsiblePlay.vue'),
        },
        {
          path: 'faq',
          name: 'Faq',
          props: true,
          component: () => import('../views/Faq.vue'),
        },
        {
          path: 'redirector',
          name: 'Redirector',
          props: true,
          component: () => import('../views/Redirector.vue'),
        },
        {
          path: 'reset-password',
          name: 'ResetPassword',
          component: () => import('../views/ResetPassword.vue'),
        },
      ],
    },
  ],
});

router.afterEach((to, from) => {
  // console.log('after');
  window.scrollTo(0, 0);
  window.parent.scrollTo(0, 0);
});

export { router };
