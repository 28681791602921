import utils from '../modules/utils';
//import { accessToken } from "../composables/auth";
const utilsPlugin = {
  install(Vue, objOptions) {
    let { store, router } = objOptions;

    // utils.getRootPages = () => {
    //   let objJackpotBranding = store.getters.objJackpotBranding;
    //   if (objJackpotBranding.branding_page_roots) {
    //     return objJackpotBranding.branding_page_roots;
    //   }
    //   return [];
    // };

    utils.getAttribVal = (strDefaultLabel, strAttribute) => {
      let objJackpotBranding = store.getters.objJackpotBranding;
      if (objJackpotBranding.branding_attr_vals) {
        let objBrandingAttr = objJackpotBranding.branding_attr_vals.find((o) => o.attr_type == strAttribute);
        return objBrandingAttr && objBrandingAttr.value ? objBrandingAttr.value : strDefaultLabel;
      }
      return strDefaultLabel;
    };

    utils.showSnackbar = (strMessage) => {
      Vue.prototype.$buefy.snackbar.open({
        duration: 5000,
        message: strMessage,
        container: 'div#app',
        position: 'is-bottom-right',
      });
    };
    utils.CopyToClipboard = (e) => {
      navigator.clipboard.writeText(e.innerText);
    };
    utils.dropDownOptions = {
      showDialCodeInList: true,
      showDialCodeInSelection: true,
      showFlags: true,
    };

    utils.getSignedUrl = async (strContentType) => {
      let strUrl = '/u/gsu';
      let objResponse = await Vue.prototype.$myAxios.get(strUrl, {
        params: { strContentType },
      });
      if (objResponse.blnOK) {
        return objResponse.obj;
      }
      return null;
    };

    utils.getAvatarFileName = (objUser) => {
      let strAvatarFileName = objUser.avatar_filename;
      if (strAvatarFileName) {
        let strUrl = 'https://s3.eu-west-1.amazonaws.com/imprexis-banners-bucket/';
        strUrl += strAvatarFileName;
        return strUrl;
      }
      var images = require.context('../assets/', false);
      return images('./' + 'jackpotaruba.png');
    };
    utils.handleS3Upload = async (file) => {
      let objPresignedPost = await utils.getSignedUrl();
      if (!objPresignedPost) {
        throw new Error('Presigned url not available');
      }
      const formData = new FormData();
      formData.append('Content-Type', file.type);

      Object.entries(objPresignedPost.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('file', file);

      const instance = axios.create();
      const res = await instance.post(objPresignedPost.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          delete headers.common['Authorization'];
          return data;
        },
        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.fltPercentCompleted = percentCompleted;
          // console.log(percentCompleted / 100);
        },
      });
      this.blnImgUploading = false;

      // console.log('The headers are ', res.headers);
      const location = res.headers.location; // get the final url of our uploaded image
      let strImgUrl = decodeURIComponent(location);

      if (strImgUrl) {
        let arrTemp = strImgUrl.split('/');
        // console.log('arrTemp is', arrTemp)
        if (arrTemp.length > 1) {
          let strImgName = arrTemp[arrTemp.length - 1];
          this.$emit('uploaded', strImgName);
          this.$emit('input', strImgName);
        }
      } else {
        window.alert('Image could not be uploaded, please try again!');
      }
    };

    utils.getUploadedFileUrl = (strFileName) => {
      return 'https://s3.eu-west-1.amazonaws.com/imprexis-banners-bucket/' + strFileName;
    };

    Vue.prototype.$utils = utils;
  },
};

export default utilsPlugin;
